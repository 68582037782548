.error-page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 10px 50px 80px;
    font-family: "Arial", sans-serif;
    background-color: #f8f9fa;
    height: 100vh;
    text-align: center;
}

.error-message {
    max-width: 50%;
}

.error-code {
    font-size: 4rem;
    color: #ff4e4e;
    margin: 0;
}

.text {
    font-size: 1.5rem;
    color: #1e255e;
    margin: 10px 0;
    font-weight: 600;
}

.suggestion {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 20px;
}

.homepage-button {
    background-color: #343a40;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}



.error-image {
    position: relative;
}

.character {
    position: relative;
    display: inline-block;
    width: 60%;
}

.speech-bubble {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff6b6b;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.character-image {
    max-width: 100%;
    height: auto;
}
