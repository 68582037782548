.status-icon-style {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.02857em;
    /*border: 1px solid rgb(130, 214, 22);*/
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    transition: 150ms ease-in;
    font-size: 0.75rem;
    background-image: initial;
    background-position-y: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: transparent;
    /*color: rgb(130, 214, 22);*/
    border-radius: 50%;
    width: 1.5875rem;
    min-width: 1.5875rem;
    height: 1.5875rem;
    min-height: 1.5875rem;
    padding: 0;
    background-size: 150% !important;
    background-position-x: 25% !important;
}

.paypal-icon {
    color: #003087;
    font-size: 2rem;
}

.credit-card-icons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.visa-icon {
    color: #1A1F71;
    font-size: 2rem;
}

.mastercard-icon {
    font-size: 2rem;
    background: linear-gradient(90deg, #EB001B 0%, #F79E1B 100%);
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}

.badge-success {
    color: rgb(17.4935064935, 162.5064935065, 70.8725201179);
    background-color: rgb(156.2441558442, 237.9558441558, 186.3220779221);
}

.badge-pending{
    color: rgb(79.2765957447, 79.2765957447, 104.7234042553);
    background-color: rgb(196.9591489362, 196.9591489362, 201.2408510638);
}

.badge-failed {
    color: rgb(255, 69, 58);
    background-color: rgb(255, 228, 225);
}

.badge-refunded {
    color: rgb(255, 165, 0);
    background-color: rgb(255, 240, 204);
}

.badge-sm {
    padding: .45em .775em;
    font-size: .65em;
    border-radius: .25rem;
}

.badge {
    text-transform: uppercase;
}