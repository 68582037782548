.input-style {
    width: 100%;
    padding: 10px 0px;
    border: none;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
    font-family: Arial, Roboto;
}

.icon-style {
    margin-right: 8px;
    color: #888;
}

.label-style {
    font-size: 14px;
    font-weight: bold;
    color: #3f4a63;
    margin-bottom: 8px;
    display: inline-block
}

.error-text{
    color: red;
    font-size: 12px;
}

.dialog-container {
    padding: 16px;
  }