
.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    margin: 0 auto;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}
.react-pdf__Page__canvas:active {
    background: transparent;
    border: none;
    outline: none;
}
.textLayer {
    z-index: auto !important;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page {
    margin-top: 10px;
}

.digitizationEditorToolbar {
    border: 1px solid #e0d8d8b0;
    border-bottom: none;
    display: flex;
    gap: 0.5em;
    padding-left: .5%;
    padding-top: .5%;
    height: 7%;
}

.DigitizationEditorCanvas {
    border: 1px solid #e0d8d8b0;
    height: 93%;
}

.digitizationEditorContentSection {
    border: 1px solid #e0d8d8b0;
    width: 50%;
    border-left: none;
    overflow-y: auto;
}


.labelingEditorHeader {
    height: 8%;
}



.yai4eduEditorLayout {
    height: 93vh;
    display: flex;
    flex-direction: column;
}

.body-component  {
    display: flex;
    height: 100%;
}
.body-elements {
    width: 50%;
}
.digitizationEditorBody {
    height: 93%;
}
.resize-handle {
    height: 100%;
    cursor: col-resize;
    position: absolute;
    top: 0;
    width: 10px;
}

.digitization-note{
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border: thin solid #dcdbdb;
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    height: auto;
}



